import { KeyValuePair } from './to-array';

export function generateSeasonsList(): KeyValuePair[] {
  const startingYear = 2024;
  const currentYear = new Date().getFullYear();
  const seasons: KeyValuePair[] = [];

  for (let i = startingYear; i <= currentYear; i++) {
    const seasonId = `${i}-${i + 1}`;
    seasons.push({ key: seasonId, value: seasonId });
  }

  return seasons;
}
