export function formatFileSize(sizeInBytes: number): string {
  if (sizeInBytes === null || sizeInBytes === undefined) {
    return 'Taille inconnue';
  }

  const sizes = ['octets', 'Ko', 'Mo'];

  const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
  if (i === 0) {
    return sizeInBytes + ' ' + sizes[i];
  }

  return (sizeInBytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
}
