export function isNil<T>(value: T | null | undefined): value is T | null | undefined {
  return value === null || value === undefined;
}

export function isNilOrEmpty<T>(value: T | string | null | undefined): boolean {
  if (isNil(value)) {
    return true;
  }
  if (typeof value === 'string') {
    return (value as string).trim().length === 0;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (value as any).length === 0;
}
