export interface KeyValuePair {
  key: string;
  value: string;
}

export function toArray<T>(obj: T): KeyValuePair[] {
  if (!isObject(obj)) {
    console.error('Invalid object input:', obj);
    return [];
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.entries(obj as Record<string, any>).map(([key, value]) => ({ key, value })) as KeyValuePair[];
}

function isObject(value: unknown): boolean {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
}
