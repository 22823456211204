import { getMonth, getYear } from 'date-fns';

import type { SeasonId } from '@epi/types';

const SEASON_LAST_MONTH_INDEX = 5;

export function getSeasonUid(date?: string | Date): SeasonId {
  const monthIndex = getMonth(date ?? new Date());
  if (monthIndex > SEASON_LAST_MONTH_INDEX) {
    const currentYear = getYear(date ?? new Date());
    return `${currentYear}-${currentYear + 1}`;
  }
  const currentYear = getYear(date ?? new Date());
  return `${currentYear - 1}-${currentYear}`;
}
